exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-frontend-mentor-js": () => import("./../../../src/pages/frontendMentor.js" /* webpackChunkName: "component---src-pages-frontend-mentor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-learning-js": () => import("./../../../src/pages/machineLearning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-mer-nshop-js": () => import("./../../../src/pages/MERNshop.js" /* webpackChunkName: "component---src-pages-mer-nshop-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-storybook-js": () => import("./../../../src/pages/storybook.js" /* webpackChunkName: "component---src-pages-storybook-js" */),
  "component---src-pages-weather-js": () => import("./../../../src/pages/weather.js" /* webpackChunkName: "component---src-pages-weather-js" */)
}

